export const size = {
  sm: "425px",
  md: "768px",
  lg: "1024px",
  xl: "1440px",
}

export const device = {
  minSm: `(min-width: ${size.sm})`,
  minMd: `(min-width: ${size.md})`,
  minLg: `(min-width: ${size.lg})`,
  minXl: `(min-width: ${size.xl})`,
  maxSm: `(max-width: ${size.sm})`,
  maxMd: `(max-width: ${size.md})`,
  maxLg: `(max-width: ${size.lg})`,
  maxXl: `(max-width: ${size.xl})`,
}
